.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

*,
*::before,
*::after{
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: black;
}

meter::-webkit-meter-optimum-value {
  background-image: linear-gradient(to left, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.5));
  height: 50px;
}

* {
  transition: all 0.2s;
}