.weekPreviewContainer {
  width: 100%;
  margin-bottom: 2em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1em;
  border: 1px solid rgb(255, 255, 255, 0.05);
  background-image: linear-gradient(120deg, rgb(255, 255, 255,0.2),hsl(0, 0%, 100%,0.1), rgb(21, 19, 113,0.1) );
  color: white;
}

.statusContainer {
  margin-bottom: 1em;
  width: 100%;
  text-align: right;
}

.status {
  display: inline-block;
}

.title {
  width: 100%;
  text-align: center;
  font-size: calc(0.5em + 2vmax);
  font-weight: bold;
  margin-bottom: 00.5em;
}

.meter {
  width: 60%;
}

.progress {
  font-weight: bold;
}

