.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.8));
}

.dashboardContainer {
  width: 100%;
  max-width: 1024px;
  padding: 4em;
  color: white;
}

.title {
  font-size: calc(1rem + 2vmax);
  font-weight: bold;
  margin-bottom: 48px;
}

.progressText {
  font-size: calc(0.8rem + 2vmax);
  font-weight: 500;
}

.statusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.status, .progress {
  font-size: calc(0.8rem + 1vmax);
}

.meter {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .dashboardContainer {
    padding: 2em;
  }
}

@media screen and (max-width: 425px) {
  .dashboardContainer {
    padding: 1em;
  }
}