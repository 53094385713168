.startupContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.caption {
  width: auto;
  margin-right: 6em;
  color: white;
}

.boldCaption {
  font-weight: bold;
  font-size: 2.5rem;
}

.lightCaption {
  font-weight: 500;
}

.startupMenu {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.startupMenu::-webkit-scrollbar{
  display: none;
}

.arrowRight {
  position: absolute;
  right: -3.5rem;
}

.arrowLeft {
  position: absolute;
  left: 12rem;
}

.arrowLeft > i{
  padding: 1em;
  border-radius: 50%;
  background: rgb(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
}

.arrowRight > i{
  padding: 1em;
  border-radius: 50%;
  background: rgb(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .startupContainer {
    flex-direction: column;
  }
  
  .arrowLeft {
    right: 4em;
    left: 0;
    top: 8em;
    display: flex;
    justify-content: flex-end;  
  }

  .arrowRight {
    top: 8em;
    right: 0;
  }

  .caption {
    width: 100%;
    margin: 0;
    margin-bottom: 2em;
  }
}
