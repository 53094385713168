.startupContainer {
  width: 100%;
  padding: 0 6em;
  margin-top: calc(2em + 3vmax);
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.5));
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.RHS {
  width: 67%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 996px) {
  .startupContainer {
    padding: 0 3em;
  }
}

@media screen and (max-width: 768px) {
  .RHS {
    width: 100%;
    /* margin-left: 10%; */
  }
}

@media screen and (max-width: 540px) {
  .startupContainer {
    padding: 0 1em;
  }
  .RHS {
    /* width: 85%; */
    /* margin-left: 15%; */
  }
}
