.incubatorContainer {
  width: 100%;
  margin-top: calc(2em + 3vmax);
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.5));
}

.incubatorOverview {
  width: 100%;
  padding: 3em 4em;
}

@media screen and (max-width: 768px) {
  .incubatorOverview {
    padding: 3em 4em;
    margin: 0 auto;
  }
}

@media screen and (max-width: 540px) {
  .incubatorOverview {
    padding: 3em 2em;
  }
}

@media screen and (max-width: 425px) {
  .incubatorOverview {
    padding: 3em 1em;
  }
}