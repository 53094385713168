.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 1.5em;
  padding-bottom: 0;
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
  flex-wrap: wrap;
  background: white;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
}

.iconContainer > .arrowLeft {
  border: 1px solid gray;
  border-radius: 50%;
  margin-right: 1em;
  color: black;
  background: rgb(128, 128, 128, 0.5);
  font-size: 1.2rem;
  cursor: pointer;
}

.iconContainer > .icon {
  width: 3em;
  height: auto;
  margin-right: 1em;
}

.iconContainer > .name {
  font-weight: bold;
  font-size: 1.5rem;
}

.fundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.fundContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 1em;
}

.fundTextContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fundText {
  font-weight: bold;
  margin-right: 1em;
  font-size: 1.1em;
}

.percentage {
  font-weight: bold;
  font-size: 0.8em;
}

.fund {
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
}

.meter {
  width: 12em;
}

.fundButton {
  padding: 0.7em 2.5em;
  border-radius: 0.2em;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.8));
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 996px) {
  .fundWrapper{
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .iconContainer > .icon {
    display: none;
  }
  
  .iconContainer > .name {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .header {
    margin-bottom: 0.5em;
    padding: 0.5em;
  }

  .iconContainer {
    margin-bottom: 0;
  }

  .iconContainer > .arrowLeft {
    font-size: 1rem;
  }

  .iconContainer > .arrowLeft > i {
    padding: 0.5em;
  }

  .fundButton {
    padding: 0.4em 1.5em;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }
}