.dashboardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
}

.video {
  width: 100%;
  height: 28em;
}

@media screen and (max-width: 768px) {
  .video{
    height: 24em;
  }
}

@media screen and (max-width: 425px) {
  .video{
    height: 12em;
  }
}