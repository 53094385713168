.servicesContainer {
  width: 100%;
  max-width: 1240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5em 0;
  flex-wrap: wrap;

}

.img_1,
.img_2,
.img_3 {
  width: 24.5em;
  height: auto;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img_1 > img,
.img_2 > img,
.img_3 > img {
  width: 100%;
  height: auto;
}

.button {
  position: absolute;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  padding: 0.8em 2em;
  border-radius: 0.3em;
}

@media screen and (max-width: 996px) {
  .img_1,
  .img_2,
  .img_3 {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .img_1,
  .img_2,
  .img_3 {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .button {
    padding: 0.5em 1em;
    font-size: 0.8;
  }
}