.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.8));
}

.dashboardContainer {
  width: 100%;
  max-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4em 6em;
  color: white;
}

.imageContainer {
  position: absolute;
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid white;
  background: rgb(255, 0, 184, 0.9);
}

.infoWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.infoContainer {
  width: 32em;
}

.name {
  font-size: calc(1rem + 3vmax);
  font-weight: bold;
}

.progress {
  margin: 24px 0;
  font-size: calc(0.5rem + 2vmax);
  font-weight: 500;
}

.resumeButtonContainer {
  width: 100%;
  text-align: center;
}

.resumeButton {
  display: inline-block;
  padding: 1em 5em;
  border-radius: 1em;
  font-weight: 500;
  background: #151371;
  font-size: 1.1em;
}

@media screen and (max-width: 768px) {
  .dashboardContainer{
    padding: 3em;
    flex-direction: column;
    align-items: flex-end;
  }

  .imageContainer{
    position: static;
  }

  .infoWrapper,
  .infoContainer {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .dashboardContainer {
    padding: 1em;
  }
  .name {
    text-align: center;
  }
  .progress {
    text-align: center;
  }
  .imageContainer {
    width: 4em;
    height: 4em;
  }
}