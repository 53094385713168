.previewContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 3em;
  padding: 1em;
  border-radius: 1em;
  background-image: linear-gradient(120deg, rgb(255, 255, 255,0.2),hsl(0, 0%, 100%,0.1), rgb(21, 19, 113,0.1) );
  border: 1px solid rgba(153, 75, 75, 0.05);
  color: white;
  position: relative;
}

.courseImagePreview {
  max-width: 14em;
  width: 40%;
  height: auto;
  margin-right: 2em;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.status {
  padding: 0 calc(1.5em);
  border-radius: 0.6em;
  background: green;
  color: white;
  position: absolute;
}

.courseTitle {
  width: 100%;
  font-size: calc(0.7rem + 2vmax);
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .previewContainer{
    margin-bottom: 2em;
  }
}


@media screen and (max-width: 540px) {
  .previewContainer {
    border-radius: 0.5em;
  }
}

@media screen and (max-width: 425px) {
  .previewContainer {
    padding: 0.5em;
    margin-bottom: 1em;
  }
  .courseImagePreview {
    width: 8em;
    height: 6em;
  }
  .courseTitle {
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
  }
  .status {
    padding: 0.1 1em;
    border-radius: 0.6em;
    font-size: 0.8rem;
  }
}