.sidebarContainer {
  width: 33%;
  margin-right: 1em;
  background: white;
}

.wrapper {
  padding: 1.5em 2.5em;

}

.foundDate {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1.5em;
  padding: 1.5em;
  border-bottom: 1px solid rgb(190, 190, 190);
}


.websiteContainer,
.teamsizeContainer,
.locationContainer,
.teamsContainer,
.tagsContainer {
  margin-bottom: 1.5em;
}

.websiteContainer > .title,
.locationContainer > .title,
.teamsizeContainer > .title {
  font-size: 0.8rem;
  font-weight: bold;
}

.locationContainer .value,
.teamsizeContainer .value {
  font-weight: bold;
}

.websites * {
  color: #2164f3;
}

.websites a:first-child {
  font-weight: bold;
}

.link {
  display: inline-block;
  padding: 0 0.4em;
  margin-left: 0.5em;
}

.teamsContainer .title,
.tagsContainer > .title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.5em;
}

/* .teams {

} */

.team {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.8em;
}

.team > .avatar {
  width: 2.5em;
  height: 2.5em;
  background: gray;
  border-radius: 50%;
  margin-right: 0.5em;
}

.team > .info > .name {
  font-weight: bold;
}

.team > .info > .role {
  font-weight: bold;
  font-size: 0.8rem;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tag {
  border: 1px solid rgb(192, 191, 191);
  border-radius: 0.2em;
  margin-right: 0.5em;
  padding: 0 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .sidebarContainer {
    width: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
}