.mainContainer {
  width: 100%;
  background: white;
  padding: 1em 2.5em;
}

.aboutContainer {
  margin-bottom: 2em;
}

.title {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.text {
  font-weight: 500;
}

@media screen and (max-width: 540px){
  .mainContainer {
    padding: 1em;
  }
}