.startupWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.startupContainer {
  width: 100%;
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18em, 1fr)); 
  gap: 1em;
}

.caption {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 6em;
  color: white;
}

.boldCaption {
  width: auto;
  font-weight: bold;
  font-size: 2.5rem;
}

.lightCaption {
  font-weight: 500;
  width: auto;
}

.loadButton {
  margin-top: 2em;
  display: inline-block;
  border: 2px solid rgb(192, 191, 191);
  padding: 0.5em 3em;
  border-radius: 0.2em;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 540px) {
  .startupContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .caption {
    margin: 0;
    margin-bottom: 2em;
  }
}