.coursePreviewContainer {
  border: 1px solid rgb(192, 191, 191, 0.1);
  margin-right: 1em;
  display: inline-block;
  white-space: normal;
  border-radius: 1em;
  padding: 1rem 2em;
  margin-bottom: 3em;
  background-image: linear-gradient(150deg, rgb(255, 255, 255,0.5), rgb(21, 19, 113,0.2), hsl(0, 0%, 100%,0.3));
}

.incentiveContainer {
  width: 100%;
  text-align: right;
  margin-bottom: 0em; 
}

.incentive {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 3em;
  border-radius: 0.6em;
  position: relative;
  background: rgb(69, 177, 69);
  color: rgb(3, 63, 3);
}

.incentive::before{
  left: 0.5em;
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  background: green;
  border-radius: 50%;
}

.textContainer {
  margin-bottom: 0em;
  color: white;
}

.textContainer > .title {
  font-size: 2rem;
  font-weight: bold;
}

.textContainer > .subtitle {
  font-size: 1.2rem;
  font-size: 500;
}

.learnButtonContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 0em;
}

.learnButton {
  display: inline-block;
  padding: 0.3em 2.5em;
  border-radius: 0.8em;
  margin: 1em 0;
  font-weight: bold;
  font-size: 1.2rem;
  background-image: linear-gradient(to right, rgb(255, 0, 184, 0.9), rgb(255, 199, 0, 0.8));
  color: white;
}

.courseInfo {
  color: white;
}

.info {
  width: 100%;
  text-align: right;
  font-weight: bold;
}

.range {
  width: 100%;
}

.completed {
  width: 100%;
  text-align: center;
  font-weight: bold;
}