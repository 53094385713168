.navbarContainer {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8em;
  background: white;
  z-index: 15;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(3em + 4vmax);
}

.logo {
  width: 100%;
  height: 100%;
}

.linksMobile {
  width: 100%;
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255, 0.8);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.linkMobile {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.3em 0;
  margin-bottom: 1em;
  color: #151371;
}

.link {
  margin-right: 2em;
  font-weight: 500;
  font-size: 1.2rem;
  color: #151371;
  position: relative;
  transform: translate(1em, 1em);
}

.linkMobile:hover,
.link:hover{
  color: #6c6cf3;
}

.link:last-child{
  margin-right: 0;
}

.bars {
  display: none;
  font-size: 2em;
  cursor: pointer;
  color: #151371;
}

.bars > i {
  font-weight: lighter;
}

@media screen and (max-width: 768px){
  .navbarContainer{
    padding: 0 5em;
  }
}

@media screen and (max-width: 540px) {
  .navbarContainer{
    padding: 0 2em;
  }
  .linksMobile {
    display: flex;
    left: 0;
    top: calc(2em + 3vmax);
  }
  .links {
    display: none;
  }
  .bars {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .navbarContainer{
    padding: 0 1em;
  }
}