.startupContainer {
  width: 100%;
  margin: 2em 0;
  background: white;
  padding: 1em 2.5em;
}

.caption {
  position: relative;
  width: 100%;
  padding: 1em 0;
  margin-right: 6em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.boldCaption {
  width: 100%;
  font-weight: bold;
  font-size: 1.5rem;
}

.lightCaption {
  font-weight: 500;
}

.startupMenu {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.startupMenu::-webkit-scrollbar{
  display: none;
}

.arrowContainer {
  right: 0;
  position: absolute;
}

.arrowLeft,
.arrowRight {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.arrowRight {
  margin-left: 3em;
}

@media screen and (max-width: 425px) {
  .boldCaption{
    width: 6em;
  }

  .arrowRight {
    margin-left: 1em;
  }
}
