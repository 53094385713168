.moduleSidebarContainer {
  width: 24em;
  padding-top: 2em;
  padding-left: 3em;
  padding-right: 2em;
  height:  calc(100vh - calc(2em + 3vmax));
  overflow-y: auto;
  position: fixed;
  top: calc(2em + 3vmax);
  color: white;
  background: #151371;
  z-index: 5;
}

.previous {
  margin-left: -1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.previous  i {
  font-weight: lighter;
  margin-right: 12px;
}

.courseTitle {
  margin-bottom: 2em;
}

.weekContainer {
  margin-bottom: 2em;
}

.weekTitle {
  margin-bottom: 0.5em;
  color: white;
}

.topicContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5em;
}

.status {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: gray;
  margin-right: 0.5em;
}

.weekTitle,
.courseTitle,
.previous,
.previous i{
  font-size: 1.2rem;
}

.topic {
  font-size: 1.1rem;
}

@media screen and (max-width: 996px) {
  .moduleSidebarContainer {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .weekTitle,
  .courseTitle,
  .previous,
  .previous i{
    font-size: 1.2rem;
  }
  .topic {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 425px) {
  .moduleSidebarContainer {
    width: 70%;
  }
}